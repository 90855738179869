import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import { authObject } from './actions';
import CommonReducer from './CommonReducer';

const reducers = combineReducers({
  menu,
  settings,
  register: CommonReducer(authObject.REGISTER_USER),
  login: CommonReducer(authObject.LOGIN_USER),

  //customer reducers
  addCustomer: CommonReducer(authObject.ADD_CUSTOMER),
  viewCustomer: CommonReducer(authObject.VIEW_CUSTOMER),
  customerDetails: CommonReducer(authObject.GET_CUSTOMER),
  editCustomer: CommonReducer(authObject.EDIT_CUSTOMER),
  deleteCustomer: CommonReducer(authObject.DELETE_CUSTOMER),
  viewPendingOrder: CommonReducer(authObject.VIEW_PENDING_ORDER),

  //employee reducers
  addEmployee: CommonReducer(authObject.ADD_EMPLOYEE),
  viewEmployee: CommonReducer(authObject.VIEW_EMPLOYEE),
  deleteEmployee: CommonReducer(authObject.DELETE_EMPLOYEE),
  employeeDetails: CommonReducer(authObject.GET_EMPLOYEE),
  editEmpoyee: CommonReducer(authObject.EDIT_EMPLOYEE),

  //driver reducers
  addDriver: CommonReducer(authObject.ADD_DRIVER),
  viewDriver: CommonReducer(authObject.VIEW_DRIVER),
  deleteDriver: CommonReducer(authObject.DELETE_DRIVER),
  driverDetails: CommonReducer(authObject.GET_DRIVER),
  editDriver: CommonReducer(authObject.EDIT_DRIVER),

  //service reducers
  addService: CommonReducer(authObject.ADD_SERVICE),
  viewService: CommonReducer(authObject.VIEW_SERVICE),
  deleteService: CommonReducer(authObject.DELETE_SERVICE),
  serviceDetails: CommonReducer(authObject.GET_SERVICE),
  editService: CommonReducer(authObject.EDIT_SERVICE),

  //account type reducers
  addAccount: CommonReducer(authObject.ADD_ACCOUNT),
  viewAccount: CommonReducer(authObject.VIEW_ACCOUNT),
  deleteAccount: CommonReducer(authObject.DELETE_ACCOUNT),
  accountDetails: CommonReducer(authObject.GET_ACCOUNT),
  editAccount: CommonReducer(authObject.EDIT_ACCOUNT),

  //cloth type reducers
  addCloth: CommonReducer(authObject.ADD_CLOTH),
  viewCloth: CommonReducer(authObject.VIEW_CLOTH),
  deletCloth: CommonReducer(authObject.DELETE_CLOTH),
  clothDetails: CommonReducer(authObject.GET_CLOTH),
  editCloth: CommonReducer(authObject.EDIT_CLOTH),

  //price list reducers
  addPrice: CommonReducer(authObject.ADD_PRICE),
  viewPrice: CommonReducer(authObject.VIEW_PRICE),
  deletPrice: CommonReducer(authObject.DELETE_PRICE),
  priceDetails: CommonReducer(authObject.GET_PRICE),
  editPrice: CommonReducer(authObject.EDIT_PRICE),

   //plant reducers
   addPlant: CommonReducer(authObject.ADD_PLANT),
   viewPlant: CommonReducer(authObject.VIEW_PLANT),
   plantDetails: CommonReducer(authObject.GET_PLANT),
   editPlant: CommonReducer(authObject.EDIT_PLANT),
   deletePlant: CommonReducer(authObject.DELETE_PLANT),

  //user type reducers
  addUserType: CommonReducer(authObject.ADD_USERTYPE),
  viewUserType: CommonReducer(authObject.VIEW_USERTYPE),
  deletUserType: CommonReducer(authObject.DELETE_USERTYPE),
  userTypeDetails: CommonReducer(authObject.GET_USERTYPE),
  editUserType: CommonReducer(authObject.EDIT_USERTYPE),
  getUser: CommonReducer(authObject.GET_USER_BY_TYPE),

  //user reducers
  addUser: CommonReducer(authObject.ADD_USER),
  viewUser: CommonReducer(authObject.VIEW_USER),
  deletUser: CommonReducer(authObject.DELETE_USER),
  userDetails: CommonReducer(authObject.GET_USER),
  editUser: CommonReducer(authObject.EDIT_USER),

  //expense category reducers
  addExpenseCategory: CommonReducer(authObject.ADD_EXPCATEGORY),
  viewExpenseCategory: CommonReducer(authObject.VIEW_EXPCATEGORY),
  deletExpenseCategory: CommonReducer(authObject.DELETE_EXPCATEGORY),
  expenseCategoryDetails: CommonReducer(authObject.GET_EXPCATEGORY),
  editExpenseCategory: CommonReducer(authObject.EDIT_EXPCATEGORY),

  //expense details reducers
  addExpense: CommonReducer(authObject.ADD_EXPENSE),
  viewExpense: CommonReducer(authObject.VIEW_EXPENSE),
  deletExpense: CommonReducer(authObject.DELETE_EXPENSE),
  expenseDetails: CommonReducer(authObject.GET_EXPENSE),
  editExpense: CommonReducer(authObject.EDIT_EXPENSE),


  //daybook reducers
  addDaybook: CommonReducer(authObject.ADD_DAYBOOK),
  viewDaybook: CommonReducer(authObject.VIEW_DAYBOOK),
  deletDaybook: CommonReducer(authObject.DELETE_DAYBOOK),
  daybookDetails: CommonReducer(authObject.GET_DAYBOOK),
  editDaybook: CommonReducer(authObject.EDIT_DAYBOOK),

  //company details reducers
  editCompany: CommonReducer(authObject.EDIT_COMPANY),
  viewCompany: CommonReducer(authObject.GET_COMPANY),

  //general settings reducers
  editSettings: CommonReducer(authObject.EDIT_SETTINGS),
  viewSettings: CommonReducer(authObject.GET_SETTINGS),
 
  //print label reducers
  viewOrders: CommonReducer(authObject.VIEW_ORDERS),
  getLabel: CommonReducer(authObject.GET_LABEL),

  //priority list reducer
  viewPriority: CommonReducer(authObject.VIEW_PRIORITY),

  //order reducers
  viewOrder: CommonReducer(authObject.VIEW_ORDER),
  deleteOrder: CommonReducer(authObject.DELETE_ORDER),
  orderDetails: CommonReducer(authObject.GET_ORDER),
  employeeAssign: CommonReducer(authObject.ASSIGN_EMPLOYEE),
  driverAssign: CommonReducer(authObject.ASSIGN_DRIVER),
  pickupAssign: CommonReducer(authObject.PICKUP_DRIVER),

  //dashboard
  viewReadyOrder: CommonReducer(authObject.VIEW_DASHBOARD),
  viewCompleated: CommonReducer(authObject.VIEW_DASHBOARD),
  

  //pos
  viewReceivedOrder: CommonReducer(authObject.RECEIVED_VIEW),
  viewCleaningOrder: CommonReducer(authObject.CLEANING_VIEW),
  viewCompleteOrder: CommonReducer(authObject.COMPLETED_VIEW),
  closingReport: CommonReducer(authObject.CLOSING_REPORT),
  viewPos: CommonReducer(authObject.POS_VIEW),

  //report
  customerReport: CommonReducer(authObject.CUSTOMER_REPORT),
  orderReport: CommonReducer(authObject.ORDER_REPORT),
  salesReport: CommonReducer(authObject.SALES_REPORT),
  cashLedgerReport: CommonReducer(authObject.CASHLEDGER_REPORT),
  debtorsReport: CommonReducer(authObject.DEBTORS_REPORT),
  debtorsReportByOrder: CommonReducer(authObject.DEBTORS_REPORT_ORDER),
  readyForDeliveryReport: CommonReducer(authObject.READYFORDELIVERY_REPORT),
  expenseReport: CommonReducer(authObject.EXPENSE_REPORT),
  itemwiseReport: CommonReducer(authObject.ITEMWISE_REPORT),
  employeeReport: CommonReducer(authObject.EMPLOYEE_REPORT),
  employeeReportByOrder: CommonReducer(authObject.EMPLOYEE_REPORT_ORDER),
  driverReport: CommonReducer(authObject.DRIVER_REPORT),
  driverReportByOrder: CommonReducer(authObject.DRIVER_REPORT_ORDER),
  serviceWiseReport: CommonReducer(authObject.SERVICEWISE_REPORT),
  userWiseReport: CommonReducer(authObject.USERWISE_REPORT),

  //outdoor service
  viewOutdoorService: CommonReducer(authObject.VIEW_OUTDOOR_SERVICE),
  getOutdoorService: CommonReducer(authObject.GET_OUTDOOR_SERVICE),

  //outdoor order
  viewOutdoorOrder: CommonReducer(authObject.VIEW_OUTDOOR_ORDER),
  getOutdoorOrder: CommonReducer(authObject.GET_OUTDOOR_ORDER),

     //rack reducers
     addRack: CommonReducer(authObject.ADD_RACK),
     viewRack: CommonReducer(authObject.VIEW_RACK),
     rackDetails: CommonReducer(authObject.GET_RACK),
     editRack: CommonReducer(authObject.EDIT_RACK),
     deleteRack: CommonReducer(authObject.DELETE_RACK),

     //Database list reducers
     dataList:CommonReducer(authObject.DATABASE_LIST),
     notifications:CommonReducer(authObject.NOTIFICATION_LIST),
});

export const whitelistReducers = [];

export default reducers;

