
const ErrorManager = {
    handle(error) {
        // this.logOutUser();
        if (error?.response) {
            if (error?.response?.status === 401) {
                window.location.assign('/')
                localStorage.clear()
            }
            if (error?.response?.status === 405) {
                //   SBToast.error(Messages.InvlaidField);
                // this.logOutUser(store);
                return;
            }
            if (error?.response?.status === 400) {
                const message = error?.response?.data?.message;
                //   SBToast.error(message);
                return;
            }
            const message = error?.response?.data?.message;
            // message && SBToast.error(message);
        } else {
            // SBToast.error('Network Error');
        }
    }
}

export default ErrorManager;