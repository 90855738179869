import { createStore, applyMiddleware } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';
import reducers, { whitelistReducers } from './reducers';
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import ErrorManager from './ErrorManager';

const persistConfig = {
  key: "root",
  storage,
  whitelist: whitelistReducers,
};

const client = axios.create({ // all axios can be used, shown in axios documentation
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  // withCredentials: true,
});

const middlewareConfig = {
  interceptors: {
    request: [
      {
        success: async function ({ getState, dispatch, getSourceAction }, req) {
          const token = localStorage.getItem("token");
          // console.log('token', token)
          if (token) {
            req.headers["Authorization"] = `Bearer ${token}`;
            console.log("interceptors request", req)
          }
          return req;

        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
        },
      },
    ],
    response: [
      {
        success: function ({ getState, dispatch, getSourceAction }, res) {
          console.log("AXIOS RESPONSE = ", res); //contains information about request object
          return Promise.resolve(res);
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          ErrorManager.handle(error);
          return Promise.reject(error);
        },
      },
    ],
  },
};



const pReducer = persistReducer(persistConfig, reducers);
const store = createStore(
  pReducer,
  applyMiddleware(axiosMiddleware(client, middlewareConfig))
);
const persistor = persistStore(store);

export { persistor, store };
